import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";

import { PiUserSquare } from "react-icons/pi";
import {
  FeedPreviewContainer,
  GroupItemContainer,
  ProjecItemContainer,
  SchedulerItemContainer,
  StockItemContainer,
} from "../styled/search";
import { isEventCategory, showEventTime } from "../../scheduler/component/function/scheduler";
import { useRegex } from "../../../../hooks/useRegex";

// 일정
export const SchedulerItem = ({ item, searchWord = "" }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isColor = (event) => {
    if (event.gbn === "P") return `#${event.color || "139859"}`;
    if (event.gbn === "C") return "#0074ca";
    if (event.gbn === "G") return "#F46F02";
  };

  const isDday = (item) => {
    if (!item) return "";
    if (item.dday === "Y") {
      return "디데이";
    } else {
      return `${isEventCategory(item.gbn)}일정`;
    }
  };

  const handleClickEvent = () => {
    let basePath = `/mypage/detailEvent/${item.scheduleNo}`;
    let queryParams = [`path=${pathname}`];

    if (searchWord) {
      queryParams.push(`keyword=${searchWord}`);
    }

    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";
    return navigate(`${basePath}${queryString}`);
  };

  return (
    <SchedulerItemContainer
      style={{ borderLeft: `7px solid ${isColor(item)}` }}
      onClick={handleClickEvent}>
      <div className="info">
        {item.icon && (
          <img src={`/img/scheduler/scheduler_icon_${item.icon}`} alt="" style={{ width: 20 }} />
        )}
        <span>{item.title}</span>
      </div>
      <div className="org">
        <span>{isDday(item)}</span>
        {item.gbn !== "P" && <span className="orgNm">: {item.orgNm}</span>}
      </div>
      <div className="date">{showEventTime(item.startDt, item.endDt)}</div>
    </SchedulerItemContainer>
  );
};

// 그룹
export const GroupItem = ({ item }) => {
  const navigate = useNavigate();

  return (
    <GroupItemContainer onClick={() => navigate(`/group/${item.groupNo}`)}>
      <img
        src={item.imgUrl ? imgHandler(item.imgUrl) : "/img/function_22_bg.png"}
        alt="group image"
      />
      <p>{item.groupName}</p>
    </GroupItemContainer>
  );
};

// 소식
export const FeedPreviewItem = ({ item, tabChange = null, searchWord = "" }) => {
  const imgUrlArray = JSON.parse(item.imgUrl);
  const isImage = imgUrlArray && imgUrlArray.length > 0;
  const { pathname } = useLocation();

  const handleClickFeed = () => {
    if (!tabChange) return;

    if (pathname.includes("group")) {
      tabChange(4);
    } else {
      tabChange(3, searchWord);
    }
  };

  return (
    <FeedPreviewContainer onClick={handleClickFeed}>
      {isImage && (
        <div className="feedImageInfo">
          <img src={imgHandler(JSON.parse(item.imgUrl))} />
        </div>
      )}
      <div className="feedInfo">
        <span className="groupName">#{item.groupName}</span>
        <div className="content">{item.content}</div>
        <div className="mber">
          <img
            src={item.mberImgPath ? imgHandler(item.mberImgPath) : "/img/user.png"}
            alt="writer"
          />
          <span>{item.nickNm}</span>
        </div>
      </div>
    </FeedPreviewContainer>
  );
};

// 프로젝트
export const ProjectItem = ({ item }) => {
  const navigate = useNavigate();
  const isColor = (item) => {
    if (!item) return "#fff";
    if (item.pinnedYn && item.pinnedYn === "Y") return "#f8bf53";
    if (item.gbn === "G") return "#f46f02";
    if (item.partiType && item.partiType === "I") {
      return "#0074ca";
    } else {
      return "#ed5583";
    }
  };

  return (
    <ProjecItemContainer
      style={{ borderLeft: `7px solid ${isColor(item)}` }}
      onClick={() => navigate(`/mypage/project/${item.projectNo}`)}>
      <p>{item.projectNm}</p>
      <div className="userLength">
        <PiUserSquare />
        <span>{item.partiCnt}</span>
      </div>
    </ProjecItemContainer>
  );
};

// e층창고
export const StockItem = ({ item, updateImageSize, imageHeight }) => {
  const { MoneyFormat } = useRegex();
  const user = useSelector((state) => state?.user?.data[0]);

  const openCmpnyPage = async (item = null) => {
    const url = "/api/domainSettingLoad";
    const req = {
      delYn: "N",
      cmpnyNo: item.cmpnyNo,
    };

    const res = await axios.post(url, req);

    if (res.status === 200) {
      user && localStorage.setItem("user", JSON.stringify(user));

      if (res.data.domain) {
        window.open(`https://1472.ai/shop/${res.data.domain}/productsView/${item.prdNo}`);
      } else {
        toast.error("홈페이지가 존재하지 않습니다.");
      }
    }
  };

  return (
    <StockItemContainer onClick={() => openCmpnyPage(item)}>
      <div className="preview">
        <img
          src={isPrdImg(item)}
          alt={item.prductNm}
          style={{ height: `${imageHeight}px` }}
          onLoad={updateImageSize}
        />
      </div>
      <div className="product">
        <span className="prductNm">{item.prductNm}</span>
        <span className="cnsmrPc">
          {item.cnsmrPc ? `${MoneyFormat(item.cnsmrPc)}원` : "가격 문의"}
        </span>
        <span>{item.cmpnyNm}</span>
      </div>
    </StockItemContainer>
  );
};
