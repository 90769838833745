import styled, { keyframes } from "styled-components";

const mainColor = "#0074CA";
const darkColor = "#BB86FC";

export const Container = styled.div`
  position: relative;
  .inner_content {
    overflow: hidden;
    padding: 20px;
    .mypage_1 {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .slideContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
  }
  @media screen and (max-width: 768px) {
    .inner_content {
      padding: 50px 20px;
      .mypage_1 {
        padding: 0;
        margin: 0;
        border: none;
      }
    }
  }
`;

export const DailyAssistantContent = styled.div`
  position: relative;
  padding: 10px;
  min-height: 100vh;
  margin: auto;
  max-width: 1400px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const DashboardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  .logos {
    display: flex;
    align-items: center;
    height: 40px;
    overflow: hidden;
    cursor: pointer;
    img {
      height: 40px;
      object-fit: contain;
    }
  }
  .left {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .dashboardBtnArea {
      display: flex;
      align-items: center;
      button {
        width: 40px;
        height: 40px;
        font-size: 32px;
        border: none;
        color: #fff;
        margin-left: 10px;
        svg {
          display: block;
          margin: auto;
        }
        &:first-child {
          margin-left: 20px;
        }
        &.logout {
          background: #333;
          i.icon_logout {
            background: url("/img/icon_logout.png");
            background-position: 50% 50%;
            background-size: cover;
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        &.sideMenu,
        &.search {
          background: #0074ca;
        }
      }
      .notify {
        width: 40px;
        height: 40px;
        font-size: 32px;
        border: none;
        color: #fff;
        margin-left: 10px;
        position: relative;
        background: #0074ca;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(25%, -25%);
          background: #bd1e01;
          font-size: 10px;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .location {
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        align-items: center;
        &.font_14 {
          margin-left: 5px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 10px 10px 20px;
    .logos {
      img {
        width: 100px;
        height: 35px;
      }
    }
  }
`;

export const ScheduleContainer = styled.div`
  width: 100%;
  position: relative;
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    .scheduleInfo {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .userGreetingInfo {
      font-size: 28px;
      font-weight: 700;
      strong {
        color: ${mainColor};
        cursor: pointer;
      }
      span {
        display: block;
      }
    }
    .calendarNav {
      border: none;
      background: transparent;
      border-radius: 0.25em;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
    }
  }
  .viewer {
    min-height: 320px;
    padding: 0 10px;
    .noDataContainer {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    span.noDataMessageTit {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .noDataMessage {
      width: calc(22% - 15px);
      align-items: center;
      padding: 15px;
      box-sizing: border-box;
      background: #efefef;
      height: 60px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .swiperContainer {
      width: calc(25% - 15px);
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-right: 20px;
      span.tit {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
    .mySwiper {
      width: 100%;
      height: 290px;
      &:last-child {
        margin-right: 0;
      }
      .scheduleContent {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 15px;
        box-sizing: border-box;
        background: #efefef;
        height: 83px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        cursor: pointer;
        gap: 10px;
        justify-content: space-between;
        &.outside::after {
          content: "";
          width: 10px;
          height: 83px;
          background: linear-gradient(180deg, rgba(0, 116, 202, 1) 0%, rgba(237, 85, 131, 1) 100%);
          position: absolute;
          top: 0;
          left: 0;
        }
        .timeArea {
          font-size: 18px;
          font-weight: 700;
          text-align: center;
          width: 25%;
          .endOfStart {
            display: block;
            font-size: 13px;
          }
        }
        .contentArea {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          width: 70%;
          span {
            &:nth-child(1) {
              font-size: 14px;
              font-weight: 500;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            &:nth-child(2) {
              font-size: 12px;
              display: -webkit-box;
              word-wrap: break-word;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              max-height: 30px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1330px) and (min-width: 769px) {
    .viewer .mySwiper .scheduleContent {
      .timeArea {
        font-size: 15px;
        width: 30%;
      }
      .contentArea {
        width: 65%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 10px;
    .info {
      margin-bottom: 10px;
      justify-content: space-between;
      .userGreetingInfo {
        font-size: 17px;
      }
      .location {
        text-align: right;
      }
    }
    .viewer {
      flex-direction: column;
      min-height: 370px;
      margin: 25px 0;
      span.noDataMessageTit {
        font-size: 13px;
      }
      .noDataMessage {
        width: 80%;
        margin: auto;
      }
      .swiperContainer {
        width: 100%;
        span.tit {
          font-size: 13px;
        }
      }
      .mySwiper {
        height: 65px;
        .swiper-slide {
          width: 250px;
        }
        .scheduleContent {
          padding: 10px;
          height: 55px;
          &.outside::after {
            height: 55px;
          }
          .timeArea {
            font-size: 16px;
            width: 30%;
          }
          .contentArea {
            font-weight: normal;
            span {
              &:nth-child(1) {
                margin-bottom: 3px;
              }
            }
          }
        }
      }
    }
  }
`;

export const DdayContainer = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  position: absolute;
  bottom: 40px;
  left: 10px;
  .dDayInfo {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    padding: 0 10px;
  }
  .more {
    position: absolute;
    top: 60px;
    right: 0;
    padding: 0;
    border: none;
    background: transparent;
  }
  .mySwiper {
    box-sizing: border-box;
    padding: 10px;
    .swiper-slide {
      width: 250px;
    }
  }
  .ddayList {
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #efefef;
    padding: 20px 10px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-sizing: border-box;
    cursor: pointer;
    gap: 10px;
    div {
      display: flex;
      flex-direction: column;
    }
    .content {
      width: 60%;
      span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:nth-child(1) {
          font-weight: 700;
          font-size: 18px;
        }
        &:nth-child(2) {
          color: #5e5e5e;
        }
      }
    }
    .date {
      width: 40%;
      text-align: center;
      .dday {
        font-size: 20px;
        font-weight: 700;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span {
        color: #5e5e5e;
      }
    }
  }
  .noDataMessage {
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
    background: #efefef;
    height: 60px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: calc(22% - 15px);
    margin: 0 10px;
  }
  @media screen and (max-width: 768px) {
    left: 0;
    padding: 0 10px;
    .noDataMessage {
      width: 80%;
      margin: auto;
    }
    .dDayInfo {
      font-size: 13px;
    }
    .ddayList {
      padding: 10px;
      .date .dday {
        font-size: 16px;
      }
      .content span:nth-child(1) {
        font-size: 14px;
      }
    }
  }
`;

export const NotifyContainer = styled.div`
  .notifyInner {
    max-width: 1200px;
    padding: 15px 0;
    margin: auto;
  }
  .notify {
    .notifyHeader {
      border-bottom: 1px solid #efefef;
      .notifyInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          a img {
            height: 40px;
            object-fit: contain;
          }
        }
        .msgSetting {
          button {
            border: none;
            background: transparent;
          }
          .cancel {
            font-size: 15px;
          }
          .settingBtnArea {
            display: flex;
            gap: 10px;
            position: relative;
            > button {
              font-size: 30px;
              display: flex;
              align-items: center;
            }
            .setMenus {
              position: absolute;
              background: #fff;
              bottom: 0;
              right: 0;
              transform: translate(-40%, 105%);
              ul {
                width: 120px;
                border: 1px solid #efefef;
                text-align: center;
                border-radius: 10px;
                li {
                  padding: 7px 20px;
                  border-bottom: 1px solid #efefef;
                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    .notifyBody {
      .notifyInner {
        .notNotis {
          text-align: center;
          .icon {
            font-size: 35px;
            padding: 10px;
            background: #efefef;
            border-radius: 50%;
            width: 70px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 30px auto 10px;
          }
          span {
            font-size: 16px;
          }
        }
        .notifyTabMenu {
          font-size: 16px;
          margin: 20px;
          display: flex;
          font-weight: 700;
          margin: 30px 20px 45px;
          li {
            cursor: pointer;
            padding: 10px 20px;
            border: 1px solid #efefef;
            width: 150px;
            text-align: center;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
              background: #0074ca;
              color: #efefef;
              border: 1px solid #0074ca;
            }
          }
        }
        .section {
          margin: 20px 0;
          > ul {
            > li {
              border-radius: 20px;
              padding: 15px;
              box-sizing: border-box;
              margin: 10px 0;
              box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
              .notifyWrapper {
                display: flex;
                align-items: center;
                gap: 20px;
                position: relative;
                img {
                  width: 75px;
                  height: 75px;
                  border-radius: 50%;
                  border: 1px solid #efefef;
                  object-fit: contain;
                }
                .content {
                  width: calc(100% - 150px);
                  .infoBox {
                    display: flex;
                    gap: 10px;
                    .title {
                      font-weight: 600;
                    }
                    .date {
                      color: #999;
                    }
                  }
                }
                .deleteChk {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 0;
                  .cont {
                    display: flex;
                    align-items: center;
                    transform: scale(1);
                    input[type="checkbox"] {
                      height: 27px;
                      width: 27px;
                      margin: 5px;
                      display: inline-block;
                      appearance: none;
                      position: relative;
                      background-color: #ddd;
                      border-radius: 15%;
                      cursor: pointer;
                      overflow: hidden;
                      &::after {
                        content: "";
                        display: block;
                        height: 20px;
                        width: 10px;
                        border-bottom: 5px solid #efefef;
                        border-right: 5px solid #efefef;
                        opacity: 0;
                        transform: rotate(45deg) translate(-50%, -50%);
                        position: absolute;
                        top: 45%;
                        left: 21%;
                        transition: 0.25s ease;
                      }
                      &::before {
                        content: "";
                        display: block;
                        height: 0;
                        width: 0;
                        background-color: ${mainColor};
                        border-radius: 50%;
                        opacity: 0.5;
                        transform: translate(-50%, -50%);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transition: 0.3s ease;
                      }
                      &:checked {
                        &::before {
                          height: 35px;
                          width: 35px;
                          opacity: 1;
                        }
                        &::after {
                          opacity: 1;
                        }
                      }
                    }
                  }

                  span {
                    font-size: 32px;
                  }
                }
              }
              .devNotifyWrapper {
                max-height: 35px;
                overflow-y: hidden;
                transition: 0.3s;
                cursor: pointer;
                &.active {
                  max-height: 200vh;
                }
                .title {
                  display: flex;
                  align-items: center;
                  gap: 15px;
                  position: relative;
                  img {
                    width: 35px;
                    height: 35px;
                    object-fit: cover;
                    border-radius: 50%;
                  }
                  span {
                    &.noticeSj {
                      font-weight: 800;
                    }
                  }
                  .date {
                    color: #999;
                  }
                  .toggleCollapseButton {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border: none;
                    background: transparent;
                    font-size: 20px;
                    display: flex;
                  }
                  .editButton {
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    transform: translateY(-50%);
                    border: none;
                    background: #efefef;
                    padding: 5px 10px;
                    display: flex;
                    border-radius: 10px;
                    font-size: 13px;
                  }
                }
                .devNoticeContent {
                  margin-top: 10px;
                  padding-top: 10px;
                  border-top: 1px solid #efefef;
                }
              }
              .decisionBtnArea {
                display: flex;
                justify-content: space-between;
                gap: 10px;
                align-items: center;
                overflow: hidden;
                transition: 0.3s;
                height: 0;
                padding: 0;
                button {
                  width: 50%;
                  border-radius: 20px;
                  border: none;
                  padding: 5px;
                  font-size: 14px;
                  background: #efefef;
                  &:first-child {
                    background: #0074ca;
                    color: #fff;
                  }
                }
                &.inv {
                  padding: 20px 0 0;
                  height: 47px;
                }
              }
            }
          }
        }
      }
    }
  }
  .deleteLayer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 130px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    button {
      width: 300px;
      padding: 10px 0;
      border: none;
      border-radius: 30px;
      font-size: 15px;
      &:first-child {
        background: ${mainColor};
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .notifyInner {
      padding: 15px;
    }
    .notify .notifyBody .notifyInner .section ul li .notifyWrapper .content .infoBox .title {
      max-width: calc(100% - 60px);
    }
    .notify .notifyHeader .notifyInner .msgSetting .settingBtnArea .setMenus {
      transform: translate(0, 105%);
    }
  }
  @media screen and (max-width: 768px) {
    .notify {
      .notifyBody {
        .notifyInner {
          .section {
            > ul {
              > li {
                .notifyWrapper {
                  gap: 10px;
                  img {
                    width: 50px;
                    height: 50px;
                  }
                  .content {
                    width: calc(100% - 100px);
                    .infoBox {
                      flex-direction: column;
                      gap: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .notify .notifyHeader .notifyInner .title a img {
      width: 100px;
      height: 35px;
    }
    .notify .notifyBody .notifyInner .notifyTabMenu li {
      padding: 10px;
    }
  }
`;

export const CompanyInfoNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 10px;
  gap: 10px;
  &.btnArea {
    button {
      color: #fff;
      width: 200px;
      background: #0074ca;
      padding: 10px 0;
      font-size: 16px;
    }
  }
`;

export const EndOfServiceContainer = styled.div`
  .page_btn {
    border: none;
    background: none;
    padding: 0;
    margin-right: 10px;
  }
  @media screen and (max-width: 768px) {
    .page_title {
      display: block;
      margin-bottom: 10px;
    }
  }
`;

export const ModifyProfileContainer = styled.div`
  .tabMenu {
    max-width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 50px;
    li {
      padding: 10px 20px;
      font-size: 18px;
      border: 1px solid #efefef;
      box-sizing: border-box;
      cursor: pointer;
      width: 200px;
      text-align: center;
      &.active {
        background: #0074ca;
        color: #fff;
        border: 1px solid #0074ca;
      }
    }
  }
  .withdrawal {
    font-size: 18px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  ul li.no_border.img .writing_cont {
    label {
      width: 250px;
      height: 250px;
      display: inline-block;
      font-size: 15px;
      text-align: center;
      font-weight: 300;
      border: 1px solid #ccc;
      border-radius: 0;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      margin: auto;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .deleteBtn {
        border: none;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        padding: 3px;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    input[type="file"] {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    .tabMenu li {
      font-size: 14px;
      padding: 10px 0;
      width: 100px;
    }
  }
`;

export const CreateReleaseNotesContainer = styled.div`
  min-height: calc(-540px + 100vh);
  color: rgb(0, 0, 0);
  box-sizing: border-box;
  position: relative;
  .content {
    box-sizing: border-box;
    max-width: 1000px;
    margin: 50px auto;
    .ck-content {
      height: 400px;
    }
    input[type="text"] {
      border: 1px solid rgb(204, 206, 209);
      padding: 10px 15px;
      width: 100%;
      resize: none;
      margin-bottom: 10px;
    }
    .btnArea {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 50px;
      gap: 10px;
      button {
        padding: 10px 30px;
        border: 1px solid transparent;
        font-size: 16px;
        &:first-child {
          background: #0074ca;
          color: #efefef;
        }
      }
    }
  }
`;

export const NotifyBenefitContainer = styled.div`
  .my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
  }
  .benefitItem {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid #efefef;
    padding: 15px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .storeContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 15px;
      .storeThumb {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        outline: 1px solid #efefef;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .storeNm {
        font-weight: 700;
      }
      .category {
        color: #999;
      }
    }
    .productContainer {
      display: flex;
      gap: 10px;
      .info {
        width: calc(100% - 110px);
        font-size: 14px;
        .title {
          font-weight: 600;
        }
        .desc {
          display: block;
          margin: 5px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        .tag {
          display: inline-block;
          padding: 3px;
          border: 1px solid #efefef;
          font-size: 12px;
          color: #0074ca;
        }
      }
      .thumb {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
`;

export const ManagementContainer = styled.div`
  ul.mypage_status.div_width_50 {
    width: 70%;
    li {
      width: 170px;
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      padding: 0;
      a {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }
      &#status_P {
        background-color: #139859;
      }
      &#status_C {
        background-color: #0074ca;
      }
      &#status_G {
        background-color: #f46f02;
      }
    }
  }
  div.mypage_btn_wrap.div_width_50 {
    width: 30%;
  }
  @media screen and (max-width: 1050px) {
    ul.mypage_status.div_width_50 {
      width: 100%;
    }
    div.mypage_btn_wrap.div_width_50 {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    ul.mypage_status.div_width_50 {
      width: 100%;
      li {
        width: 125px;
        height: 125px;
      }
    }
    div.mypage_btn_wrap.div_width_50 {
      width: 100%;
    }
  }
`;

export const CompanyPageDashboard = styled.div`
  margin-top: 3%;
  button {
    border: none;
    background: transparent;
  }
  .inviteBtnArea {
    width: 100%;
    margin: 20px auto;
    text-align: right;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    input[type="text"] {
      padding: 0 10px;
      border: 1px solid #efefef;
      height: 100%;
    }
    button {
      padding: 10px 30px;
      font-size: 14px;
      background: #efefef;
    }
  }
  table {
    width: 100%;
    margin: auto;
    border: 1px solid #efefef;
    border-collapse: collapse;
    th,
    td {
      border: 1px solid #efefef;
    }
    thead {
      tr th {
        background: #0074ca;
        color: #fff;
        padding: 10px;
        font-size: 16px;
        border-right: 1px solid #efefef;
        &:last-child {
          border-right: none;
        }
      }
    }
    tbody {
      tr td {
        font-size: 14px;
        padding: 5px;
        text-align: center;
        select {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          &:focus {
            box-shadow: none;
            border: none !important;
          }
        }
        button {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .inviteBtnArea {
      flex-wrap: wrap;
      button {
        padding: 10px 15px;
      }
    }
  }
`;

const fadeInOut = keyframes`
  0% { top: -100%; }  
  10% { top: 0; }      
  90% { top: 0; }     
  100% { top: -100%; } 
`;

export const InstallContainer = styled.div`
  z-index: 10000;
  width: 100vw;
  font-weight: 600;
  font-size: 16px;
  position: fixed;
  top: -100%;
  /* top: 0; */
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;
  background: #fff;
  padding: 15px;
  border-radius: 0 0 15px 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  animation: ${fadeInOut} 10s forwards;
  .content {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  button {
    border: none;
    width: max-content;
    padding: 5px 10px;
    border-radius: 5px;
    background: #000;
    color: #fff;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
