import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { SectionSwiperContainer } from "./styled/main";

const SectionSwiper = () => {
  const openAppInstall = (flag) => {
    let url =
      "https://apps.apple.com/kr/app/%EB%AA%A8%EB%85%B8%ED%8B%B0-monoti-%EA%B3%B5%EC%9C%A0%EC%BA%98%EB%A6%B0%EB%8D%94-%EA%B8%B0%EB%B0%98-%ED%98%91%EC%97%85%ED%88%B4/id6473604609";
    if (!flag) {
      url =
        "https://play.google.com/store/apps/details?id=com.notitle.toktokCalendar&pcampaignid=web_share";
    }

    window.open(url, "_blank");
  };

  return (
    <SectionSwiperContainer>
      <div className="inner">
        <div className="content">
          <span className="title">모노티</span>
          <strong>
            공유 캘린더 기반
            <br />
            협업툴
          </strong>
          <span>
            일정을 손쉽게 관리하고
            <br />
            그룹과 공유해보세요.
          </span>
          <div className="buttons">
            <button onClick={() => openAppInstall(false)}>
              <img src="/img/main_visual_playstore.png" alt="playstore" />
              <span>GooglePlay</span>
            </button>
            <button onClick={() => openAppInstall(true)}>
              <img src="/img/main_visual_apple.png" alt="apple" />
              <span>App Store</span>
            </button>
          </div>
        </div>
        <div className="img">
          <img src="/img/main_mockup_1.png" alt="" />
        </div>
      </div>
    </SectionSwiperContainer>
  );
};

export default SectionSwiper;
