import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../modal";
import { onClickLogout } from "../../store/user";
import { resetSetting } from "../../store/scheduler";
import { useMediaQuery } from "react-responsive";

const AuthButtons = ({ setClickSideMenu = null }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.data[0]);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [notify, setNotify] = useState([]);
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  useEffect(() => {
    if (user) {
      getNotification();
    }
  }, [user]);

  const getNotification = async () => {
    try {
      const res = await axios.post("/api/notificationHistory", { mberNo: user.mberNo });
      setNotify((res?.data || []).filter((not) => not.readYn === "N"));
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogoutModal = () => setModalOpen(!modalOpen);

  const onClickModalCheck = async () => {
    await dispatch(onClickLogout());
    await dispatch(resetSetting());
    setModalOpen(false);
    navigate("/login");
  };

  const LogoutModalForm = () => (
    <div>
      <h2 className="font_20 font500 font_color_white">로그아웃</h2>
      <button onClick={handleLogoutModal} className="hp_step_setting_wrap_close">
        <i className="fa fa-times" aria-hidden="true" />
      </button>
      <span className="font_18 font300 font_color_gray pdt_20 text_center">
        로그아웃 하시겠습니까?
      </span>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
        <button
          onClick={handleLogoutModal}
          className="hp_step_setting_cate_save"
          style={{ padding: "10px 20px", background: "#6C757D" }}>
          취소
        </button>
        <button
          onClick={onClickModalCheck}
          className="hp_step_setting_cate_save"
          style={{ padding: "10px 20px" }}>
          확인
        </button>
      </div>
    </div>
  );

  const generateButtonData = () => {
    if (!user) {
      return [
        { label: "로그인", className: "login", onClick: () => navigate("/login") },
        { label: "회원가입", className: "type", onClick: () => navigate("/type") },
      ];
    }

    return [
      { label: "로그아웃", className: "logout", onClick: handleLogoutModal },
      { label: "마이페이지", className: "mypage", onClick: () => navigate("/mypage") },
      !isTablet && {
        label: "검색",
        className: "search",
        onClick: () => navigate("/mypage/search"),
      },
      !isTablet && {
        label: "알림",
        className: "notify",
        onClick: () => navigate("/mypage/notify"),
        component: (
          <Link
            to="/mypage/notify"
            target="_blank"
            onClick={() => localStorage.setItem("user", JSON.stringify(user))}>
            알림 {notify.length > 0 && <span>({notify.length < 99 ? notify.length : "99+"})</span>}
          </Link>
        ),
      },
    ].filter(Boolean);
  };

  const buttonData = generateButtonData();

  const handleButtonClick = (clickEvent) => {
    console.log(setClickSideMenu);
    if (setClickSideMenu) {
      setClickSideMenu(false);
    }
    clickEvent();
  };

  return (
    <>
      <Modal
        modalState={modalOpen}
        handleModalState={handleLogoutModal}
        html={LogoutModalForm()}
        w="300px"
        h="auto"
      />
      <div className="buttons">
        {buttonData.map((button, index) =>
          button.component ? (
            <div
              key={index}
              onClick={() => handleButtonClick(button.onClick)}
              className={button.className}>
              {button.component}
            </div>
          ) : (
            <button
              key={index}
              onClick={() => handleButtonClick(button.onClick)}
              className={button.className}>
              {button.label}
            </button>
          ),
        )}
      </div>
    </>
  );
};

export default AuthButtons;
