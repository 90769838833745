import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel } from "swiper";
import moment from "moment";
import axios from "axios";

import Clouds from "./component/dashboardPage/dailyAssistant/clouds";
import Rain from "./component/dashboardPage/dailyAssistant/rain";
import Sunny from "./component/dashboardPage/dailyAssistant/sunny";
import Snow from "./component/dashboardPage/dailyAssistant/snow";

import DailyAssistant from "./component/dashboardPage/dailyAssistant";
import Company from "./component/dashboardPage/company";
import EndOfService from "./component/dashboardPage/endOfService";
import HomePage from "./component/dashboardPage/homepage";
import Management from "./component/dashboardPage/management";
import RecentProject from "./component/dashboardPage/recentProject";

import { ddaysSorted, isSameDay } from "../scheduler/component/function/scheduler";

import { Container } from "./component/styled/dashboard";
import Install from "./component/dashboardPage/install";
import SessionManager from "../../../common/sessionManager";

SwiperCore.use([Mousewheel]);

export default function Mypage() {
  const location = useLocation();

  const user = useSelector((state) => state?.user?.data[0]);
  const { mySchedSet } = useSelector((state) => state?.scheduler);

  const slideIndex = location.state?.slideIndex;

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const [clickSideMenu, setClickSideMenu] = useState(null);
  const [companyInfo, setCompanyInfo] = useState({});
  const swiperRef = useRef(null);
  const [schedules, setSchedules] = useState({
    P: [],
    C: [],
    G: [],
    O: [],
  });
  const [userlocation, setUserLocation] = useState("");
  const [ddays, setDdays] = useState([]);
  const [todayWeather, setTodayWeather] = useState();
  const [projectCount, setProjectCount] = useState([
    { name: "개인", cnt: 0, key: "P" },
    { name: "업무", cnt: 0, key: "C" },
    { name: "그룹", cnt: 0, key: "G" },
    { name: "완료", cnt: 0, key: "end" },
  ]);
  const [weatherUI, setWeatherUI] = useState(null);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const today = moment();

  useEffect(() => {
    if (user) {
      handleCreateSession();
    }

    if (user && Object.keys(mySchedSet).length) {
      fetchLocation();
      getSchedule();
      getWeather();
      getProjectList();
    }

    if (window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone) {
      return;
    }

    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handler);
    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(slideIndex || 0);
    }
  }, [slideIndex]);

  useEffect(() => {
    const swiperInstance = swiperRef.current?.swiper;
    if (swiperInstance) {
      if (clickSideMenu) {
        swiperInstance.mousewheel.disable();
      } else {
        swiperInstance.mousewheel.enable();
      }
    }
  }, [clickSideMenu]);

  // 새로운 세션 생성
  const handleCreateSession = async () => {
    const sessionData = JSON.parse(sessionStorage.getItem("monotiSessionData"));
    const sessionId = sessionData?.id;

    if (sessionId) {
      await handleSessionEnd(sessionId);
    }

    try {
      const data = {
        userId: user.mberNo,
        os: "web",
        screenId: "W1",
      };

      const response = await axios.post("/api/session/createSession", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = response.data;

      if (res?.createdAt) {
        sessionStorage.setItem("monotiSessionData", JSON.stringify({ ...res }));
      }
    } catch (error) {
      console.error("세션 생성 오류", error);
      console.log("서버 오류가 발생했습니다.");
    }
  };

  // 세션 종료
  const handleSessionEnd = async (sessionId) => {
    const url = "/api/session/endSession";
    try {
      const response = await axios.post(
        url,
        { sessionId },
        {
          headers: { "Content-Type": "application/json" },
          timeout: 2000,
        },
      );

      const { success } = response.data;
      if (success) {
        sessionStorage.removeItem("monotiSessionData");
      }
    } catch (error) {
      console.error("세션 종료 요청 실패", error);
    }
  };

  const installApp = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("사용자가 PWA를 설치했습니다.");
        } else {
          console.log("사용자가 PWA 설치를 거부했습니다.");
        }

        setDeferredPrompt(null);
      });
    }
  };

  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getUserAddress(latitude, longitude);
        },
        () => {
          setUserLocation("대한민국");
        },
      );
    } else {
      setUserLocation("대한민국");
    }
  };

  const getUserAddress = async (latitude, longitude) => {
    try {
      const url = "/api/getAddress";
      const body = {
        latitude,
        longitude,
      };

      const res = await axios.post(url, body);
      const address = res.data.documents[0].address;
      const city = address.region_1depth_name;
      const district = address.region_2depth_name;
      setUserLocation(`${city} ${district}`);
    } catch (error) {
      setUserLocation("대한민국");
    }
  };

  const openNewTab = async () => {
    const url = "/api/domainSettingLoad";
    const body = {
      delYn: "N",
      cmpnyNo: user.cmpnyNo,
    };

    await axios.post(url, body).then((response) => {
      setCompanyInfo(response.data);
      window.open(`https://1472.ai/shop/${response.data.domain}`);
      user && localStorage.setItem("user", JSON.stringify(user));
    });
  };

  const getSchedule = async () => {
    const isOrgNo = (org) => {
      if (!org) return [];
      return org.map((item) => item.orgNo);
    };

    const url = "/api/scheduleList";
    const body = {
      mberNo: user.mberNo,
      showPersonalYn: "Y",
      cmpnyNos: isOrgNo(mySchedSet.companyYnList),
      groupNos: isOrgNo(mySchedSet.groupYnList),
      completeYn: "D",
      startDt: today.format("YYYY-MM-DD 00:00:00"),
      endDt: today.format("YYYY-MM-DD 23:59:59"),
    };
    const res = await axios.post(url, body);
    const data = res.data;

    const schedule = data.filter((event) => {
      return event.dday === "N";
    });

    splitDataByGbn(schedule);
    settingDdays(data);
  };

  const splitDataByGbn = (data) => {
    const initialSchedules = {
      P: [],
      C: [],
      G: [],
      O: [],
    };

    const newSchedules = data.reduce((acc, item) => {
      const gbn = item.gbn;

      if (acc[gbn]) {
        acc[gbn].push(item);
      }

      return acc;
    }, initialSchedules);

    const filteredSchedules = Object.fromEntries(
      Object.entries(newSchedules).filter(([_, value]) => value.length > 0),
    );

    setSchedules(filteredSchedules);
  };

  const settingDdays = (data) => {
    const filtered = data.filter((item) => item.dday !== "N" && item.completeYn == "N");
    const sorted = ddaysSorted(filtered);

    setDdays(sorted);
  };

  const getWeather = async () => {
    const url = "/api/weather";
    const today = moment().format("YYYY-MM-DD");
    const req = {
      startDt: today,
      endDt: today,
    };
    const res = await axios.post(url, req);
    if (res.status === 200) {
      const data = res.data[0];
      setTodayWeather(data);

      const weather = data?.weather;

      if (weather === 0) {
        setWeatherUI(() => <Sunny />);
      } else if (weather === 5) {
        setWeatherUI(() => <Snow />);
      } else if ([3, 4, 6].includes(weather)) {
        setWeatherUI(() => <Rain />);
      } else if ([1, 2].includes(weather)) {
        setWeatherUI(() => <Clouds />);
      } else {
        setWeatherUI(null);
      }
    }
  };

  const getProjectList = async () => {
    const url = "/api/projectList";
    const body = {
      mberNo: user.mberNo,
      delYn: "N",
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      const data = res.data;
      splitData(data);
    }
  };

  // 프로젝트 분리
  const splitData = (data) => {
    const combinedProjects = [
      ...(data.cmpnyProjectList || []),
      ...(data.fixedProjectList || []),
      ...(data.groupProjectList || []),
      ...(data.otherProjectList || []),
    ];

    const newProjectCounts = [
      { name: "개인", cnt: 0, key: "P" },
      { name: "업무", cnt: 0, key: "C" },
      { name: "그룹", cnt: 0, key: "G" },
      { name: "완료", cnt: 0, key: "end" },
    ];

    combinedProjects.forEach((project) => {
      if (project.completeYn === "Y") {
        newProjectCounts.find((item) => item.key === "end").cnt += 1;
      } else {
        newProjectCounts.find((item) => item.key === project.gbn).cnt += 1;
      }
    });

    setProjectCount(newProjectCounts);
  };

  const myPageComponents = [
    [
      <DailyAssistant
        key="dailyAssistant"
        clickSideMenu={clickSideMenu}
        setClickSideMenu={setClickSideMenu}
        location={userlocation}
        schedules={schedules}
        ddays={ddays}
        todayWeather={todayWeather}
        weatherUI={weatherUI}
      />,
    ],
    [
      <Management key="management" projectCount={projectCount} />,
      <RecentProject key="recentProject" />,
    ],
    [
      <HomePage key="homepage" openNewTab={openNewTab} />,
      <Company key="company" />,
      <EndOfService key="endOfService" />,
    ],
  ];

  const renderComponents = (components) => {
    return components.map((component, index) => {
      const isDailyAssistant = component.type.displayName === "DailyAssistant";
      const componentClass = isDailyAssistant ? "" : "inner_content";

      return (
        <SwiperSlide key={component.key || index}>
          <div className={componentClass}>{component}</div>
        </SwiperSlide>
      );
    });
  };

  return (
    <Container>
      <SessionManager />
      {deferredPrompt && <Install installApp={installApp} />}
      <Swiper
        ref={swiperRef}
        direction={"vertical"}
        slidesPerView={1}
        mousewheel={true}
        pagination={{ clickable: true }}
        style={{ height: "100vh" }}
        onResize={() => {
          if (swiperRef.current) {
            swiperRef.current.swiper.update();
          }
        }}>
        {myPageComponents.map((group, groupIndex) => (
          <React.Fragment key={groupIndex}>
            {isTablet ? (
              renderComponents(group)
            ) : (
              <SwiperSlide key={`group-${groupIndex}`}>
                <div className="slideContainer">
                  {group.map((component, componentIndex) => {
                    const isDailyAssistant = component.type.displayName === "DailyAssistant";
                    const componentClass = isDailyAssistant ? "" : "inner_content sections";

                    return (
                      <div key={component.key || componentIndex} className={componentClass}>
                        {component}
                      </div>
                    );
                  })}
                </div>
              </SwiperSlide>
            )}
          </React.Fragment>
        ))}
      </Swiper>
    </Container>
  );
}
