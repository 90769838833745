import React, { useEffect } from "react";

const SessionManager = () => {
  const handleSessionEnd = () => {
    const sessionData = JSON.parse(sessionStorage.getItem("monotiSessionData"));
    const sessionId = sessionData?.id;

    if (sessionId) {
      const url = "/api/session/endSession";
      const data = JSON.stringify({ sessionId });

      if (navigator.sendBeacon) {
        const blob = new Blob([data], { type: "application/json" });
        navigator.sendBeacon(url, blob);
        console.log("sendBeacon 세션 종료 요청 전송 완료");
      } else {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url, false);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(data);
        console.log("AJAX 세션 종료 요청 전송 완료");
      }

      sessionStorage.removeItem("monotiSessionData");
      console.log("세션 데이터 제거 완료");
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleSessionEnd);

    return () => {
      window.removeEventListener("beforeunload", handleSessionEnd);
    };
  }, []);

  return null;
};

export default SessionManager;
