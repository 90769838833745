import React, { useEffect, useState } from "react";
import Feature from "./component/feature";
import Member from "./component/member";
import Video from "./component/video";
import SectionSwiper from "./component/sectionSwiper";
import { MainContainer } from "./component/styled/main";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { mainSection } from "./component/function/common";
import SessionManager from "../../common/sessionManager";
import axios from "axios";

// 메인페이지
const Main = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);

  useEffect(() => {
    handleCreateSession();
  }, []);

  // 새로운 세션 생성
  const handleCreateSession = async () => {
    const sessionData = JSON.parse(sessionStorage.getItem("monotiSessionData"));
    const sessionId = sessionData?.id;

    if (sessionId) {
      await handleSessionEnd(sessionId);
    }

    try {
      const data = {
        os: "web",
        screenId: "W0",
      };

      const response = await axios.post("/api/session/createSession", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = response.data;
      console.log(res);

      if (res?.createdAt) {
        console.log(`세션 생성 성공: ${res.id}`);
        sessionStorage.setItem("monotiSessionData", JSON.stringify({ ...res }));
      } else {
        console.log(`세션 생성 실패: ${res}`);
      }
    } catch (error) {
      console.error("세션 생성 오류:", error);
      console.log("서버 오류가 발생했습니다.");
    }
  };

  // 세션 종료
  const handleSessionEnd = async (sessionId) => {
    const url = "/api/session/endSession";
    try {
      const response = await axios.post(
        url,
        { sessionId },
        {
          headers: { "Content-Type": "application/json" },
          timeout: 2000,
        },
      );

      const { success } = response.data;
      if (success) {
        sessionStorage.removeItem("monotiSessionData");
      }
    } catch (error) {
      console.error("세션 종료 요청 실패:", error);
    }
  };

  const openMyPage = async () => {
    const url = "/api/domainSettingLoad";
    const req = {
      delYn: "N",
      cmpnyNo: user.cmpnyNo,
    };

    const res = await axios.post(url, req);
    if (res.data.domain) {
      window.open(`https://1472.ai/shop/${res.data.domain}`);
      user && localStorage.setItem("user", JSON.stringify(user));
    } else {
      navigate("/mypage/homepage");
    }
  };

  const handleNavigate = (item) => {
    if (item.key === "shop") {
      openMyPage();
    } else {
      navigate(`${item.path}`);
    }
  };

  return (
    <MainContainer id="fullpage">
      <SessionManager />
      <SectionSwiper />
      {mainSection.map((item) => (
        <div className={`mainSection ${item.key}`}>
          <div className="inner">
            <div className="img">
              <img src={`/img/main_section_${item.key}.png`} />
            </div>
            <div className="content">
              <span className="title">{item.title}</span>
              <strong>{item.info}</strong>
              <span className="details">{item.details}</span>
              <button onClick={() => handleNavigate(item)}>바로가기</button>
            </div>
          </div>
        </div>
      ))}
      {/* <Video />
      <Feature />
      <Member /> */}
    </MainContainer>
  );
};

export default Main;
