import React from "react";
import { InstallContainer } from "../styled/dashboard";

const Install = ({ installApp }) => {
  return (
    <InstallContainer>
      <div className="content">
        모노티 웹을 바탕화면에 추가하시겠습니까?
        <button onClick={installApp}>추가하기</button>
      </div>
    </InstallContainer>
  );
};

export default Install;
