import styled from "styled-components";

export const LoadingContainer = styled.div`
  position: fixed;
  z-index: 99999;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  font-size: 70px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export const HeaderContainer = styled.header`
  padding: 0;
  position: relative;
  background: #191f28;
  &.group {
    background: #fef9f3;
    .headerInner nav .dropdown:hover .dropdown-menu {
      background: #fef9f3;
    }
  }
  &.scheduler {
    background: #ffd9f2;
    .headerInner nav .dropdown:hover .dropdown-menu {
      background: #ffd9f2;
    }
  }
  &.project {
    background: #fff299;
    .headerInner nav .dropdown:hover .dropdown-menu {
      background: #fff299;
    }
  }
  &.shop {
    background: #eaf7ed;
    .headerInner nav .dropdown:hover .dropdown-menu {
      background: #eaf7ed;
    }
  }
  &.group,
  &.scheduler,
  &.project,
  &.shop {
    .headerInner {
      .logo,
      .sideMenu,
      a,
      .headerBtnArea button,
      .headerBtnArea a {
        color: #000;
      }
    }
  }
  h1 {
    display: none;
  }
  .headerInner {
    max-width: 1400px;
    padding: 10px 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .logo {
      font-size: 22px;
      color: #fff;
      font-weight: 700;
      height: auto;
    }
    nav {
      width: calc(100% - 500px);
      text-align: left;
      > ul {
        display: flex;
        flex-direction: row;
      }
      .dropdown {
        position: relative;
        display: flex;
        align-items: center;
        width: 150px;
        padding: 9px 0;
        a {
          display: inline-block;
          padding: 0;
          text-align: center;
          width: 100%;
        }
        .dropdown-menu {
          padding: 0;
          width: 100%;
          opacity: 0;
          transform: none;
          position: absolute;
          overflow: hidden;
          transition: 0.5s;
          background: #191f28;
          top: 100%;
          left: 50%;
          transform: translate(-50%, 0);
          gap: 30px;
          white-space: nowrap;
          z-index: 33;
          li {
            width: auto;
          }
        }
        &:hover {
          .dropdown-menu {
            /* width: 500px; */
            opacity: 1;
          }
        }
      }
      li {
        margin-right: 0;
      }
    }
    .dropdown-menu {
      width: 150%;
      padding: 20px 0;
      li a {
        padding: 0;
        transition: 0.3s;
        &:hover {
          font-weight: 700;
          transform: scale(1.1);
        }
      }
    }
    .buttons {
      display: flex;
      font-size: 14px;
      gap: 10px;
      button,
      a {
        border: none;
        background: transparent;
        color: #fff;
      }
      button:nth-child(2) {
        margin-right: 30px;
      }
      .notify {
        position: relative;
        span {
          position: absolute;
          top: 0;
          left: 100%;
          font-size: 0;
          transform: translateX(3px);
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: tomato;
          text-align: center;
          line-height: 15px;
        }
      }
    }
    .sideMenu {
      display: flex;
      font-size: 28px;
      border: none;
      background: transparent;
      color: #fff;
    }
  }
  @media screen and (max-width: 1400px) {
    .headerInner {
      padding: 10px 20px;
    }
  }
  @media screen and (max-width: 1050px) {
  }
  @media screen and (max-width: 768px) {
    .headerInner {
      .login_menu {
        width: auto;
        transform: translateY(0);
      }
      img.logo {
        width: 100px;
        height: 35px;
      }
    }
  }
`;

export const SideMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background: #191f28;
  transition: 0.3s;
  a,
  button {
    color: #fff;
  }
  button {
    border: none;
    background: transparent;
  }
  .wrapper {
    padding: 20px;
    width: 100%;
    height: 100%;
    font-size: 18px;
    .sideMenuInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
      .logo {
        font-size: 20px;
        font-weight: 600;
      }
      .buttons {
        font-size: 25px;
        display: flex;
        align-items: center;
        gap: 10px;
        button,
        a {
          width: 25px;
          height: 25px;
          text-align: center;
          &:last-child {
            font-size: 30px;
            margin-left: 20px;
          }
          display: flex;
        }
        a {
          position: relative;
          span {
            position: absolute;
            top: 0;
            left: 100%;
            font-size: 0;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: tomato;
            text-align: center;
          }
        }
      }
    }
    .authButtons {
      margin-top: 50px;
      .buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        button,
        a {
          display: block;
          width: 100%;
          text-align: center;
          background: rgba(255, 255, 255, 0.1);
          padding: 10px 0;
          border-radius: 10px;
          font-size: 14px;
        }
        .login {
          order: 1;
        }
        .type {
          order: 2;
        }
        .logout {
          order: 4;
        }
        .mypage {
          order: 3;
        }
      }
    }
    .toggle {
      width: 100%;
      margin: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span:first-child {
        font-weight: 700;
      }
      .icon {
        font-size: 20px;
        svg {
          display: block;
        }
      }
    }
    .menuItem {
      padding-left: 20px;
      li {
        margin: 10px;
      }
    }
  }
`;

export const GridSideMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  .sideMenuInner {
    max-width: 1400px;
    width: 100%;
    height: 100%;
    position: relative;
    margin: auto;
  }
  .sideMenuWrapper {
    position: absolute;
    top: 80px;
    right: 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #000;
    .sideMenuContainer {
      position: relative;
      max-width: 300px;
      .arrowUp {
        position: absolute;
        top: 1px;
        transform: translateY(-100%);
        right: 9px;
        width: 0;
        height: 0;
        border-bottom: 15px solid rgb(239, 239, 239);
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
      }
      .sideMenuHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background: rgb(239, 239, 239);
        border-bottom: 2px solid #efefef;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .logo {
          font-size: 20px;
          display: flex;
          align-items: center;
          gap: 5px;
          img {
            width: 30px;
          }
        }
        .close {
          button {
            font-size: 25px;
            border: none;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .sideMenuBody {
        max-height: 450px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        .tableEditAddBtnArea {
          padding: 20px;
        }
        .tit {
          font-weight: 700;
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 22px;
          padding: 10px;
          background: #efefef;
          border-radius: 10px 10px 0 0;
          span {
            font-size: 18px;
          }
        }
        .border {
          border-top: 1px solid #efefef;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin: 10px;
          li {
            width: calc((100% - 30px) / 4);
            padding: 10px 0;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
            .icon {
              display: flex;
              justify-content: center;
              font-size: 20px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1280px) {
      .sideMenuInner {
        width: 96%;
      }
    }
    @media screen and (max-width: 1050px) {
      .sideMenuInner {
        padding: 0;
      }
      .sideMenuWrapper {
        right: 0;
      }
    }
    @media screen and (max-width: 767px) {
      .sideMenuInner {
        width: 90%;
      }
    }
  }
`;

export const AppInstallContainer = styled.div`
  overflow: hidden;
  width: 100vw;
  height: calc(100vh - 500px);
  margin: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 300px;
    height: 100px;
    font-size: 30px;
    border: none;
    background: #0074ca;
    color: #fff;
    border-radius: 30px;
  }
`;
